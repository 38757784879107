//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'

import { defineStore } from 'pinia'
export const useSegmentationStatusStore = defineStore('segmentationStatusStore', {
  state: () => ({
    segmentationStatuses: [] as any[],
    segmentationStatusesCorrection: [] as any[],
    segmentationStatusesIncVirtual: [] as any[]
  }),
  actions: {
    async fetchSegmentationStatuses() {
      if (this.segmentationStatuses.length === 0) {
        try {
          console.log('fetchSegmentationStatuses')
          const result = await app.axios.get(apiURL + '/segmentationStatus')
          this.segmentationStatuses = [...result.data]

          let data: any[] = [...result.data]
          data.splice(2, 0, {
            canClose: true,
            canCloseAdmin: true,
            ident: 21,
            includes: null,
            name: 'CORRECTION',
            nameDe: 'Korrektur',
            nameFr: 'Correction',
            nameIt: 'Correzione'
          })
          data.map((item) => {
            item.disabled = true
          })
          this.segmentationStatusesCorrection = data
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchSegmentationStatusesIncVirtual() {
      if (this.segmentationStatusesIncVirtual.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/segmentationStatus?includeVirtual=true')
          this.segmentationStatusesIncVirtual = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
